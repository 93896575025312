@import 'variables';

.is-container {
  padding: 30px !important;

  .page-header {
    margin-top: 20px;
  }

  .c-label-sl {
    margin: 5px;
    text-align: right;
    font-size: 14px;
  }
  
  .remark {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0 !important;
  }

  .pp-card {
    padding: 18px 10px;
    border-radius: 10px;
    background-color: #edf4fa;
  }

  .card-container {
    padding: 18px 13px 18px 13px;
    border: solid 1px #9db2ce;
    border-radius: 20px;
    margin-bottom: 0 !important;
    background-color: #edf4fa;

    .card-body {
      display: flex;
      flex-direction: row;
      padding-bottom: 5px;

      &.revert {
        flex-direction: row-reverse;
      }

      .card-img {
        width: 130px;
        padding: 0 5px;
        img {
          width: inherit;
        }
      }

      .card-content {
        width: 100%;
        padding: 0 5px;

        .c-title {
          font-weight: 700;
          font-size: 16px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
          border-radius: 50px;
          padding: 4px 10px;
          text-align: center;
          margin-bottom: 5px;
        }

        .c-name {
          font-weight: 700;
          font-size: 14px;
          text-align: center;
          margin-bottom: 4px;
        }

        .c-detail {
          padding: 0 5px;

          font-size: 12px;
          line-height: 15px;

          p {
            margin: 0px;
          }
        }
      }
    }

    .card-action {
      margin-top: 10px;
    }
  }
}

.wrapper-inspiration {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
}