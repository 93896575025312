@import 'variables';

.prize-collection-container {
  display: flex;
  flex-direction: column;
  padding: 30px;

  .pc-box-container {
    display: flex;
    margin-bottom: 20px;

    .pc-image-container {
      width: 150px;
      height: 150px;
      .pc-image {
        width: inherit;
        height: inherit;
        border-radius: 24px;
      }
    }

    .pc-gift-content {
      width: 100%;
      padding-left: 18px;

      .pc-gift-title {
        font-weight: 700;
        font-size: 16px;
      }

      .pc-gift-point {
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .secondary-text {
          color: #b3b3b3;
        }

        .point-image {
          width: 16px;
          margin: 0 2px;
        }
      }
    }
  }
}

.wrapper-prize-collection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
}
