.hp-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  background: linear-gradient(93.44deg, #e1e6ea 4.69%, #e9f5ff 118.6%);
  box-shadow: inset -8px -8px 24px rgba(255, 255, 255, 0.5),
    inset 4px 4px 8px rgba(170, 170, 170, 0.7);
  border-radius: 20px;
  border: solid 1px #fff;
  padding-top: 2px;
  margin-bottom: 4px;

  &.sm {
    width: 50px;
    height: 16px;
  }

  &.md {
    width: 56px;
    height: 20px;
  }

  .point {
    font-weight: 700;
    margin-right: 2px;

    &.sm {
      font-size: 12px;
    }

    &.md {
      font-size: 14px;
    }
  }

  .heart-img {
    width: 16px;
    margin: -1px 2px 0 2px;
  }
}
