@import 'variables';

.el-container {
  padding: 30px;

  .page-header {
    margin-top: 20px;
  }

  .point-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    .label {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  .btn {
    padding: 19px;
    border-radius: 10px;
    text-align: center;
    color: $font-color-primary;
    margin-bottom: 20px;
    font-weight: 600;
    cursor: pointer;
  }

  .paper {
    padding: 18px;
    border-radius: 10px;
    color: $font-color-primary;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 20px;

    p {
      margin: 10px 0 0 0;
    }
  }
}
