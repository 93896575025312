@import 'variables';

.ws-container {
  padding: $container-padding;
  display: flex;
  flex-direction: column;
  align-items: center;

  .c-label-sl {
    margin: 5px;
    align-self: end;
    font-size: 14px;
  }

  .remark {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;
  }

  .card-container {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    border-bottom: solid 1px #9db2ce;

    &:last-child {
      border-bottom: none;
    }

    .card-img {
      width: 102px;
      margin-right: 20px;
      img {
        width: inherit;
      }
    }

    .card-content {
      width: 100%;
      .c-title-container {
        display: flex;
        justify-content: space-between;
        .c-title {
          font-weight: 700;
          font-size: 14px;
          margin: 0 10px 4px 0;
        }
      }
      .c-sub-title {
        font-weight: 700;
        font-size: 12px;
      }
      .c-detail {
        font-size: 12px;
        margin-bottom: 10px;
        p {
          margin: 0px;
        }
      }
      .c-period {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .c-time-round {
          font-weight: 400;
          font-size: 10px;
        }
      }
      .c-remark {
        font-size: 10px;
        &::before {
          content: '**';
          color: #ff4572;
        }
      }
    }
  }
}
