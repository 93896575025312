@import 'variables';

body {
  margin: 0;
  color: $font-color-primary;
  font-family: 'Poppins', 'IBMPlexSansThai', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', 'IBMPlexSansThai', source-code-pro, Menlo, Monaco,
    Consolas, 'Courier New', monospace;
}

.logo {
  img {
    width: 64px;
    height: 64px;
  }
}

.page-header {
  font-size: 25px;
  font-weight: 600;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-72 {
  margin-top: 72px;
}

.ml-2 {
  margin-left: 2px;
}
