@import 'variables';

.we2-container {
  display: flex;
  flex-direction: column;
  // padding: $container-padding;
  padding: 30px;

  .w2-menu-container {
    padding-top: 10px;
    .w2-box-container {
      margin-bottom: 20px;
      border: solid 2px #ffffff;
      border-radius: 20px;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(2px);
      height: 160px;
      .w2-image-container {
        // padding: 5px 25px 0px 35px;
        padding: 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 72px;
      }

      .w2-button-container {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .point-container {
        display: flex;
        justify-content: flex-end;
        padding: 5px 5px 0 0;
        position: relative;
        z-index: 100;
      }
    }
  }

  .wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
