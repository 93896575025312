.bl-container {
  display: flex;

  .logo-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    .logo-1 {
      padding-top: 4px;
    }
    .logo-2 {
      border-left: solid 1px #1b1464;
      border-right: solid 1px #1b1464;
      padding: 6px 11px 0 11px;
      margin: 0 11px;
    }
    .logo-3 {
      display: flex;
      align-items: center;
      font-size: 13px;
    }
  }
}
