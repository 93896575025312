@import 'variables';

.wi-container {
  padding: $container-padding;

  .banner-img {
    width: 100%;
  }

  .paper {
    padding: 18px;
    border-radius: 10px;
    color: $font-color-primary;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 20px;

    p {
      margin: 10px 0 0 0;
    }
  }
}
