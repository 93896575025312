@import 'variables';

.li-container-bg {
  min-height: 100vh;
  // background-image: url('../../../public/assets/images/bg-login.svg');
  background-image: url('../../../public/assets/images/bg-main-layout-2.png');

  background-repeat: no-repeat;
  background-size: cover;
  // overflow: hidden;
  color: #262261;

  .li-container {
    padding: 65px 50px;
    overflow: auto;
    max-width: 480px;
    margin: 0 auto;

    .logo {
      margin-right: -34px;
    }

    .title {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0.1em;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1em;
    }

    .input-container {
      display: flex;
      flex-direction: row;
      border: 1px solid #e1e6ea;

      border-radius: 15px;
      z-index: 100;

      background: linear-gradient(
        93.44deg,
        #e1e6ea 22.69%,
        rgba(233, 245, 255, 0) 118.6%
      );
      align-items: center;
      overflow: hidden;
      padding: 15px 0 15px 15px;

      input {
        outline: none;
        border: none;
        background: none;
        font-size: 1em;
        padding: 0.5em;
        color: #323030;
        letter-spacing: 0.1em;

        flex: auto 1 1;
        width: 100%;
        background-color: transperant;

        &::placeholder {
          letter-spacing: 0.1em;
          font-weight: 400;
          font-size: 14px;
          color: #b0b0b0;
        }
      }

      .fa {
        padding: 0px 0px 0px 0px;
        margin-right: 20px;
      }
    }

    .wm-btn {
      outline: none;
      border: 3px solid #e1e6ea;
      border-radius: 100px;
      background: linear-gradient(
        93.44deg,
        #e1e6ea 22.69%,
        rgba(233, 245, 255, 0) 118.6%
      );

      padding: 20px 40px;
      font-weight: 700;
      font-size: 18px;
      text-decoration: none;
      color: #173144;
      cursor: pointer;
      // pointer-events: none;
    }
  }

  .mt-20 {
    margin-top: 10px;
  }

  .mt-30 {
    margin-top: 15px;
  }

  .mt-60 {
    margin-top: 30px;
  }

  .mt-90 {
    margin-top: 45px;
  }
}

@media (min-height: 770px) {
  .li-container-bg {
    .li-container {
      padding: 95px 56px;
    }

    .mt-20 {
      margin-top: 20px;
    }

    .mt-30 {
      margin-top: 30px;
    }

    .mt-60 {
      margin-top: 60px;
    }

    .mt-90 {
      margin-top: 90px;
    }
  }
}
