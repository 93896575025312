.bo-root {
  min-height: 100%;
  display: flex;

  .bo-topbar-container {
    top: 0;
    position: fixed;
    width: 100%;
    height: 55px;
    z-index: 900;
  }

  .bo-sidebar-container {
    z-index: 899;

    background-color: #ffffff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    flex-shrink: 0;

    display: flex;
    flex-direction: row;

    .bo-sidebar-open {
      opacity: 1;
      width: 240px;
      padding-top: 55px;
      -webkit-transition: all 0.7s ease;
      -moz-transition: all 0.7s ease;
      -ms-transition: all 0.7s ease;
      -o-transition: all 0.7s ease;
      transition: all 0.7s ease;
      .colllapse-container{
        background-color: #dfdfdf;
      }
      .iconCollapse{
        position: absolute;
        right: 10%;
      }
    }
  }

  .bo-content-container {
    width: calc(100% - 240px);
    min-height: 100vh;

    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    background-color: #f7f7f8;

    padding: 82px 20px 50px 20px;
    overflow-x: hidden;

    .bo-body-container {
      max-width: 1200px;
      overflow-x: auto;
    }
  }
 
}

@media screen and (min-width: 541px) {
  .bo-root {
    .bo-sidebar-container {
      .bo-sidebar-open {
        padding-top: 70px;
      }
    }
  }
}
