.ld-container {
  min-height: 100vh;
  max-height: 100vh;
  background-color: #c4e3f7;
  background-image: url('../../../public/assets/images/bg-main-layout-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  .main-logo {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
