@import 'variables';

.book-container {
  display: flex;
  flex-direction: column;
  padding: 30px;

  .page-header {
    margin-top: 20px;
  }

  .b-box {
    box-sizing: border-box;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 20px;
    border: 2px solid #fff;
    padding: 18px;
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: 0.5fr 1fr;

    .b-img {
      width: 100%;
      height: auto;
      margin-right: 18px;
    }

    .b-box-content {
      width: 100%;

      .b-title {
        font-weight: 600;
        font-size: 14px;
      }
      .b-desc {
        font-size: 12px;
        margin-bottom: 10px;
        min-height: 90px;
      }
    }
  }
}

.wrapper-book {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
}