@import 'variables';

.contact-container {
  padding: $container-padding;

  .contact-box-container {
    box-sizing: border-box;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 20px;
    padding: 18px;
    border: 2px solid #fff;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;

    .contact-profile-img {
      width: 80px;
      margin-right: 10px;

      img {
        width: 80px;
        background-color: white;
      }
    }

    .contact-table {
      tr td {
        &:first-child {
          width: 48px;
        }
      }
      td {
        padding-bottom: 10px;
        vertical-align: top;
      }
      .txt-primary {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 15px;
        color: #0b2b92;
      }
      .txt-secondary {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #9db2ce;
      }
    }
  }
}
