.nl-container {
  min-height: 100vh;
  width: 100vw;
  background-color: #c5e3f7;

  // background-image: url('../../../public/assets/images/bg-main-layout.svg');
  // background-position: bottom 20px right 0px;
  // background-repeat: no-repeat;
  // background-size: 100% auto;

  .reponsive-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .body {
      width: 100%;
      // max-width: 480px;
    }
  }
}
