//Variable
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1563px;

$container-padding: 24px 20px 56px 20px;

$font-color-primary: #0b2b92;

// Font
@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansThai';
  src: url('assets/fonts/IBMPlexSansThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DB Heavent';
  src: url('assets/fonts/DB-Heavent.ttf') format('truetype');
}
